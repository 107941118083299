import React, { createRef } from "react";
import {
  Modal,
  Button,
  FormRadio,
  FormInput,
  FormSelect,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import IframeWindTransfer from "../../../general/IframeWindTransfer";

import AuthContext from "../../../../context/AuthContext";

import { getSelfcareWindtransferExpertiseSolution } from "../../../../requests/folder";

export default class ModalCatnatR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      history: [0],
      data: {},
    };

    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && this.props.show !== prevProps.show) {
      this.setState({
        data: this.props.dataExpertiseCatnatR,
        history: this.props.dataExpertiseCatnatRPage.length
          ? this.props.dataExpertiseCatnatRPage
          : [0],
        currentPage: this.props.dataExpertiseCatnatRPage.length
          ? this.props.dataExpertiseCatnatRPage[
              this.props.dataExpertiseCatnatRPage.length - 1
            ]
          : 0,
      });
    }
  }

  getSelfcareWindtransfer = () => {
    if (
      !this.state.data.urlWindTransfer ||
      this.state.data.statusWindTransfer
    ) {
      getSelfcareWindtransferExpertiseSolution(
        {
          clientName: this.context.client.name,
          reference: this.context.sinister.reference,
          agencyMail: this.context.agency.email,
          licensePlate: this.context.vehicle.licensePlate,
          companyId: this.context.principal.companyId,
        },
        (result) => {
          if (result.success) {
            let data = this.state.data;

            data.urlWindTransfer = result.url;
            data.idWindTransfer = result.id;
            data.statusWindTransfer = false;

            this.setState({ isModalWindTransfer: true }, () => {
              this.props.updateCatnatR(data, this.state.history);
            });
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    } else {
      this.setState({ isModalWindTransfer: true });
    }
  };

  updateData = (name, value, callback = () => {}) => {
    let data = JSON.parse(JSON.stringify(this.state.data));
    data[name] = value;

    this.setState(
      {
        data,
      },
      callback
    );
  };

  backPage = () => {
    let tmpHistory = this.state.history.slice(0, -1);

    this.setState({
      currentPage: this.state.history[this.state.history.length - 2],
      history: tmpHistory,
    });
  };

  nextPage = (page = null, callback = () => {}) => {
    let tmpHistory = this.state.history;
    let tmpPage = page !== null ? page : this.state.currentPage + 1;

    tmpHistory.push(tmpPage);

    this.setState(
      {
        currentPage: tmpPage,
        history: tmpHistory,
      },
      callback
    );
  };

  getButtonBack = () => {
    if (this.state.history.length) {
      return (
        <Button
          text="Retour"
          className="m-auto mr-5 w-auto"
          onClick={() => this.backPage()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      );
    }
  };

  getButtonNext = (onClick = () => this.nextPage(), isMarginLeft = true) => {
    return (
      <Button
        text="Continuer"
        className={`m-auto ${isMarginLeft ? "ml-5" : ""} w-auto`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  };

  getCorrectInput = (isExist, name, text, required = true) => {
    const data = this.state.data;

    if (isExist) {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormRadio
            text="Oui"
            value={1}
            className="mb-10"
            checked={data[`${name}_radio`] === 1}
            onChange={() => this.updateData(`${name}_radio`, 1)}
          />
          <FormRadio
            text="Non"
            value={0}
            className="mb-10"
            checked={data[`${name}_radio`] === 0}
            onChange={() => this.updateData(`${name}_radio`, 0)}
          />
          {data[`${name}_radio`] === 0 ? (
            <FormInput
              title="Correction"
              value={data[`${name}_text`]}
              onChange={({ target }) =>
                this.updateData(`${name}_text`, target.value)
              }
            />
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormInput
            value={data[`${name}_text`] ? data[`${name}_text`] : ""}
            onChange={({ target }) => {
              this.updateData(`${name}_radio`, 0);
              this.updateData(`${name}_text`, target.value);
            }}
          />
        </>
      );
    }
  };

  getRadio = (name, text, required = true, values = []) => {
    const data = this.state.data;

    return (
      <>
        <p>
          {text}
          {required ? <span className="red"> *</span> : null}
        </p>
        {values.length ? (
          <>
            {values.map((value, idx) => (
              <FormRadio
                key={idx}
                text={value}
                value={value}
                className="mb-10"
                checked={data[`${name}_radio`] === value}
                onChange={() => this.updateData(`${name}_radio`, value)}
              />
            ))}
          </>
        ) : (
          <>
            <FormRadio
              text="Oui"
              value={1}
              className="mb-10"
              checked={data[`${name}_radio`] === 1}
              onChange={() => this.updateData(`${name}_radio`, 1)}
            />
            <FormRadio
              text="Non"
              value={0}
              className="mb-10"
              checked={data[`${name}_radio`] === 0}
              onChange={() => this.updateData(`${name}_radio`, 0)}
            />
          </>
        )}
      </>
    );
  };

  getPage = () => {
    switch (this.state.currentPage) {
      case 0:
        return (
          <>
            <h3 className="text-center">
              DÉFINISSONS ENSEMBLE VOTRE SOLUTION D'EXPERTISE
            </h3>
            <p>
              En répondant à ce questionnaire interactif, vous facilitez et
              accélérez le traitement de votre sinistre, tout en nous aidant à
              adapter votre parcours à vos attentes.
            </p>
            <div className="d-flex mt-10">
              {this.getButtonNext(undefined, false)}
            </div>
          </>
        );
      case 1:
        return (
          <>
            {this.getCorrectInput(
              !!this.context.client.cellphone,
              "cellphone",
              !!this.context.client.cellphone ? (
                <>
                  Votre numéro de téléphone portable est bien le{" "}
                  <b>{this.context.client.cellphone}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre numéro de téléphone portable</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.client.email,
              "email",
              !!this.context.client.email ? (
                <>
                  Votre adresse e-mail est bien{" "}
                  <b>{this.context.client.email}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre adresse e-mail</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.brand,
              "brand",
              !!this.context.vehicle.brand ? (
                <>
                  La marque de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.brand}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre la marque de votre véhicule</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.model,
              "model",
              !!this.context.vehicle.model ? (
                <>
                  Le modèle de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.model}</b> ?
                </>
              ) : (
                <>Merci de renseigner le modèle de votre véhicule</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.licensePlate,
              "licensePlate",
              !!this.context.vehicle.licensePlate ? (
                <>
                  L'immatriculation de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.licensePlate}</b> ?
                </>
              ) : (
                <>
                  Merci de renseigner votre l'immatriculation de votre véhicule
                </>
              )
            )}
            {this.getCorrectInput(
              false,
              "mileage",
              <>Merci de renseigner votre le kilométrage de votre véhicule</>
            )}
            <>
              <p>Lieu où se trouve actuellement le véhicule : </p>
              <FormSelect
                value={this.state.data.location_select}
                ignore={true}
                options={[
                  {
                    value: "DEFAULT",
                    text: "Veuillez sélectionner un lieu",
                    disabled: true,
                  },
                  {
                    value: "Domicile",
                    text: "Domicile",
                  },
                  {
                    value: "Réparateur",
                    text: "Réparateur",
                  },
                  {
                    value: "Dépanneur",
                    text: "Dépanneur",
                  },
                  {
                    value: "Autre",
                    text: "Autre",
                  },
                ]}
                onChange={({ target }) =>
                  this.updateData(`location_select`, target.value)
                }
              />
              {this.state.data.location_select === "Autre" ? (
                <FormInput
                  placeholder={"Autre lieu"}
                  className="mt-10"
                  onChange={({ target }) =>
                    this.updateData(`location_text`, target.value)
                  }
                />
              ) : null}
            </>
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  (this.state.data.cellphone_radio ||
                    this.state.data.cellphone_text) &&
                  (this.state.data.email_radio || this.state.data.email_text) &&
                  (this.state.data.brand_radio || this.state.data.brand_text) &&
                  (this.state.data.model_radio || this.state.data.model_text) &&
                  (this.state.data.licensePlate_radio ||
                    this.state.data.licensePlate_text) &&
                  this.state.data.mileage_text
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 2:
        return (
          <>
            {this.getRadio("rolling", "Votre véhicule est-il roulant ?")}

            {this.state.data[`rolling_radio`] === 1 ? (
              <FormInput
                title="Merci de nous indiquer le lieu de remorquage"
                value={this.state.data[`rolling_text`]}
                onChange={({ target }) =>
                  this.updateData(`rolling_text`, target.value)
                }
              />
            ) : null}

            {this.state.data[`rolling_radio`] === 0
              ? this.getRadio("rolling1", "Prévoyez-vous un remorquage ?")
              : null}

            {this.getRadio("flooded", "Votre véhicule a-t-il été inondé ?")}

            {this.state.data[`flooded_radio`] === 1 ? (
              <FormInput
                title="Pourriez-vous nous indiquer la hauteur du niveau d'eau à l'intérieur du véhicule ? (Moquette, siège, tableau de bord, véhicule complet ?)"
                value={this.state.data[`flooded_text`]}
                onChange={({ target }) =>
                  this.updateData(`flooded_text`, target.value)
                }
              />
            ) : null}

            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  (this.state.data["rolling_radio"] === 0 ||
                    this.state.data["rolling_radio"] === 1) &&
                  (this.state.data["flooded_radio"] === 0 ||
                    this.state.data["flooded_radio"] === 1)
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 3:
        return (
          <>
            {this.getRadio(
              "glass",
              "Est-ce qu'au moins un élément vitré est brisé (Pare-brise, lunette AR, vitres latérales, toit panoramique) ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.state.data["glass_radio"] === 1 ||
                  this.state.data["glass_radio"] === 0
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 4:
        return (
          <>
            {this.getRadio(
              "light",
              "Les feux arrière et / ou les optiques avant sont-ils brisés ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.state.data["light_radio"] === 1 ||
                  this.state.data["light_radio"] === 0
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 5:
        return (
          <>
            {this.getRadio(
              "repair",
              "Avez-vous choisi un réparateur afin d'effectuer la remise en état de votre véhicule ?"
            )}

            {this.state.data[`repair_radio`] === 1 ? (
              <FormInput
                title="Merci de nous l'indiquer"
                value={this.state.data[`repair_text`]}
                onChange={({ target }) =>
                  this.updateData(`repair_text`, target.value)
                }
              />
            ) : null}

            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.state.data["repair_radio"] === 1 ||
                  this.state.data["repair_radio"] === 0
                ) {
                  this.nextPage(null, () =>
                    this.props.updateCatnatR(
                      this.state.data,
                      this.state.history
                    )
                  );
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 6:
        return (
          <>
            <p>
              Nous avons bien pris en compte vos éléments. Nous vous proposons
              de nous envoyer les photos de votre véhicule afin d'accélérer le
              traitement de votre sinistre en cliquant sur lien ci-dessous
            </p>

            <Button
              className="w-auto mt-10"
              text="Prendre les photos"
              onClick={this.getSelfcareWindtransfer}
            />

            <div className="d-flex mt-10">
              {this.getButtonBack()}
              <Button
                text="Valider"
                className="m-auto ml-5 w-auto"
                onClick={() =>
                  this.props.onClose(this.state.data, this.state.history)
                }
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </>
        );
      default:
        return <div className="d-flex mt-10">{this.getButtonBack()}</div>;
    }
  };

  render() {
    return (
      <>
        <Modal
          className="m-auto"
          show={this.props.show}
          onClose={() =>
            this.props.onClose(this.state.data, this.state.history)
          }
          large
          style={{ color: "red" }}
        >
          {this.getPage()}
        </Modal>
        <IframeWindTransfer
          show={this.state.isModalWindTransfer}
          url={this.state.data.urlWindTransfer}
          onClose={(status) => {
            let data = this.state.data;

            if (status) {
              data.statusWindTransfer = true;
            }

            this.setState(
              {
                isModalWindTransfer: false,
              },
              () => {
                this.props.updateCatnatR(data, this.state.history);
              }
            );
          }}
        />
      </>
    );
  }
}
