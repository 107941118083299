import React from "react";
import { Button, Card, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import ModalElectronicSignature from "./ModalElectronicSignature";

import signature from "../../../images/signature.svg";

import {
  getElectronicSignature,
  updateElectronicSignature,
} from "../../../requests/folder";

import { getFileUrl } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class ElectronicSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataElectronicSignature: false,
      isLoad: false,
      isModalElectronicSignature: false,
      // data
      currentPage: 0,
      history: [],
      data: {},
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (
      this.props.dataTimeLine.filter(
        (element) => element.situationCodeId === 85
      ).length
    ) {
      this.getElectronicSignature();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.isDataElectronicSignature) {
      if (
        prevProps.dataTimeLine.filter(
          (element) => element.situationCodeId === 85
        ).length !==
          this.props.dataTimeLine.filter(
            (element) => element.situationCodeId === 85
          ).length &&
        this.props.dataTimeLine.filter(
          (element) => element.situationCodeId === 85
        ).length
      ) {
        this.getElectronicSignature();
      }
    }
  }

  getElectronicSignature = () => {
    this.setState(
      {
        isDataElectronicSignature: true,
        isLoad: true,
      },
      () => {
        getElectronicSignature({ folderId: this.context.id }, (result) => {
          if (result.success) {
            const currentPage = result.dataElectronicSignaturePage.length
              ? result.dataElectronicSignaturePage[
                  result.dataElectronicSignaturePage.length - 1
                ]
              : 0;

            let others = [];

            if (
              this.context.owner.name &&
              this.context.owner.name.includes(";")
            ) {
              this.context.owner.name.split(";").forEach((element, idx) => {
                if (idx > 0) {
                  others.push({
                    name: element,
                    email: this.context.owner.email
                      .split(";")
                      [idx].replace(/ /g, ""),
                    isOk: false,
                  });
                }
              });
            }

            let isOwner = false;
            let isOwnerData = false;

            if (
              this.context.owner.email &&
              this.context.owner.email
                .replace(/\//g, ";")
                .replace(/ \;/g, ";")
                .replace(/\; /g, ";")
                .split(";")[0]
                .toLowerCase() !== this.context.client.email.toLowerCase()
            ) {
              isOwner = true;
            } else if (
              this.context.owner.email &&
              this.context.owner.email
                .replace(/\//g, ";")
                .replace(/ \;/g, ";")
                .replace(/\; /g, ";")
                .split(";")[0]
                .toLowerCase() === this.context.client.email.toLowerCase()
            ) {
              isOwnerData = true;
            }

            if (result.dataElectronicSignature.hasOwnProperty("files")) {
              result.dataElectronicSignature.others = others;
            }

            let startDate = null;

            // Check
            this.props.dataTimeLine.forEach((element) => {
              if (element.situationCodeId === 85) {
                startDate = element.date;
              }
            });

            this.setState({
              isLoad: false,
              isModalElectronicSignature:
                currentPage !== 4 &&
                this.context.source === "client" &&
                !isOwner,
              data: result.dataElectronicSignature.hasOwnProperty("files")
                ? result.dataElectronicSignature
                : {
                    startDate,
                    currentDate: result.dataElectronicSignature.currentDate,
                    others,
                    kind: !isOwnerData
                      ? this.context.client.kind
                      : this.context.owner.kind,
                    name: !isOwnerData
                      ? this.context.client.name
                      : this.context.owner.name.split(";")[0],
                    email: !isOwnerData
                      ? this.context.client.email
                      : (this.context.owner.email || "").split(";")[0],
                    address1: !isOwnerData
                      ? this.context.client.address1
                      : this.context.owner.address1,
                    address2: !isOwnerData
                      ? this.context.owner.address2 ||
                        this.context.client.address2
                      : this.context.owner.address2,
                    zipCode: !isOwnerData
                      ? this.context.owner.zipCode ||
                        this.context.client.zipCode
                      : this.context.owner.zipCode,
                    city: !isOwnerData
                      ? this.context.owner.city || this.context.client.city
                      : this.context.owner.city,
                    files: [],
                    documents: [],
                    iframeUrl: null,
                    otherUrl: null,
                  },
              history: result.dataElectronicSignaturePage.length
                ? result.dataElectronicSignaturePage
                : [0],
              currentPage,
            });
          } else {
            this.setState({
              isLoad: false,
            });
          }
        });
      }
    );
  };

  updateElectronicSignature = (
    dataElectronicSignature = this.state.data,
    dataElectronicSignaturePage = this.state.history,
    isEnd = false,
    callback = () => {}
  ) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        updateElectronicSignature(
          {
            folderId: this.context.id,
            dataElectronicSignature,
            dataElectronicSignaturePage,
            isEnd,
          },
          (result) => {
            if (result.success && isEnd) {
              this.setState(
                {
                  isLoad: false,
                  isModalElectronicSignature: false,
                },
                callback
              );

              this.props.getFolder();
              this.props.getFiles();
            } else {
              this.setState({ isLoad: false }, callback);
            }
          }
        );
      }
    );
  };

  openModal = () => {
    this.setState({ isModalElectronicSignature: true });
  };

  closeModal = () => {
    this.setState({ isModalElectronicSignature: false }, () =>
      this.updateElectronicSignature()
    );
  };

  updateData = (data, isUpdate = false, callback = () => {}) => {
    this.setState({ data }, () => {
      if (isUpdate) {
        this.updateElectronicSignature(
          data,
          this.state.history,
          false,
          callback
        );
      } else {
        callback();
      }
    });
  };

  updatePage = (currentPage, history, isEnd = false, isReset = false) => {
    let data = this.state.data;

    if (isReset) {
      data.documents = [];
      data.files = [];
      data.others = [];
      data.isElectronicSignature = false;
      data.iframeUrl = null;
    }

    this.setState({ currentPage, history, data }, () =>
      this.updateElectronicSignature(undefined, undefined, isEnd)
    );
  };

  render() {
    const documents = this.context.dataDocuments.filter(
      (document) => document.typeId && [206, 346].includes(document.typeId)
    );

    const isCession = this.props.dataTimeLine.filter(
      (element) => element.situationCodeId === 85
    ).length;

    if (!isCession) {
      return false;
    }

    let isOwner = false;

    if (
      this.context.owner.email &&
      this.context.owner.email
        .replace(/\//g, ";")
        .replace(/ \;/g, ";")
        .replace(/\; /g, ";")
        .split(";")[0]
        .toLowerCase() !==
        (this.context.client.email
          ? this.context.client.email.toLowerCase()
          : "")
    ) {
      isOwner = true;
    }

    return (
      <>
        <Card
          borderTopColor="var(--red)"
          title="Proposition de cession"
          className="h-100 mb-20"
          classNameChildren="mt-auto mb-auto"
          imgRight={!this.props.isSmall ? signature : null}
          forceRight
          customWidthImgRight={"320px"}
          isLoad={this.props.isLoadDocument}
        >
          {this.context.source === "client" && !isOwner ? (
            <>
              {this.state.currentPage === 0 ? (
                <Button
                  className="mt-10"
                  text="Commencer à signer"
                  onClick={this.openModal}
                />
              ) : null}
              {this.state.currentPage > 0 && this.state.currentPage < 4 ? (
                <Button
                  className="mt-10"
                  text="Continuer à signer"
                  onClick={this.openModal}
                />
              ) : null}
            </>
          ) : null}
          {this.state.currentPage === 4 &&
          (!this.state.data.others ||
            !this.state.data.others.length ||
            (this.state.data.others.length &&
              this.state.data.others.length !==
                this.state.data.others.filter((element) => element.isOk)
                  .length)) ? (
            <>
              {this.state.data.others &&
              this.state.data.others.length &&
              this.state.data.others.length !==
                this.state.data.others.filter((element) => element.isOk)
                  .length ? (
                <>
                  <p>
                    Nous avons bien pris en compte votre signature. Nous restons
                    dans l'attente du retour des autres propriétaires afin de
                    finaliser la procédure de signature électronique.
                  </p>
                  <p>
                    <b>{this.state.data.name}</b> :{" "}
                    {this.state.currentPage !== 4
                      ? "En attente de signature"
                      : "Documents signés"}
                  </p>
                  {this.state.data.others.map((other, idx) => (
                    <p>
                      <b>{other.name}</b> :{" "}
                      {!other.isOk
                        ? "En attente de signature"
                        : "Documents signés"}
                    </p>
                  ))}
                </>
              ) : (
                <>
                  <p>
                    La procédure de signature électronique est finalisée, si
                    vous le souhaitez vous pouvez télécharger{" "}
                    {this.context.source === "client" ? "vos" : "les"} documents
                    :
                  </p>
                  <div
                    style={{
                      marginLeft: "-10px",
                      marginRight: "-10px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {documents.map((document, idx) => (
                      <div
                        key={idx}
                        style={{
                          padding: "12px",
                          height: "160px",
                          width: "160px",
                          display: "inline-flex",
                          margin: "10px",
                          border: "dashed 2px var(--red)",
                          borderRadius: "12px",
                          cursor: "pointer",
                          marginBottom: 0,
                          flexDirection: "column",
                        }}
                        onClick={() =>
                          window.open(
                            getFileUrl(
                              document.folderId,
                              document.token,
                              document.source
                            ),
                            "_blank"
                          )
                        }
                      >
                        <FontAwesomeIcon
                          style={{
                            color: "var(--red)",
                            margin: "auto",
                            fontSize: "32px",
                            marginBottom: "0",
                          }}
                          icon={faFilePdf}
                        />
                        <span
                          style={{
                            color: "var(--red)",
                            margin: "auto",
                            marginTop: "16px",
                            textAlign: "center",
                            fontSize: "14px",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {document.filename}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ) : isOwner ? (
            <>
              {this.context.source === "client" &&
              this.state.data.others &&
              this.state.data.others.filter(
                (other) => other.isLimit && !other.isOk
              ).length ? (
                this.state.data.others
                  .filter((other) => other.isLimit && !other.isOk)
                  .map((other) => (
                    <Button
                      text="Cliquer sur le bouton pour signer électroniquement"
                      onClick={() => {
                        window.open(
                          `/electronic-signature/${this.context.token}/${other.id}`,
                          "_blank"
                        );
                      }}
                    />
                  ))
              ) : (
                <p>
                  Nous sommes dans l'attente de la signature des documents de
                  cession par le propriétaire
                </p>
              )}
            </>
          ) : null}
          {this.context.source === "agency" ? (
            <Button
              className="mt-10"
              red
              text="Rénitialiser le formulaire"
              onClick={() =>
                this.updateElectronicSignature(
                  {
                    currentDate: this.state.data.currentDate,
                  },
                  [],
                  false,
                  this.getElectronicSignature
                )
              }
            />
          ) : null}
        </Card>
        <ModalElectronicSignature
          show={this.state.isModalElectronicSignature}
          onClose={this.closeModal}
          data={this.state.data}
          history={this.state.history}
          currentPage={this.state.currentPage}
          updateData={this.updateData}
          updatePage={this.updatePage}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
