import React from "react";
import { Modal, FormRadio, FormInput, Calendar } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {
  getSelfcareWindtransferExpertiseSolution,
  getVisioconferenceXpertvisioExpertiseSolution,
  getAvailabilityAgencyExpertiseSolution,
} from "../../../../requests/folder";

import IframeWindTransfer from "../../../general/IframeWindTransfer";
import Button from "../../../other/Button";

import AuthContext from "../../../../context/AuthContext";

export default class ModalExpertiseSolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      history: [],
      data: {},
      isCalendarVisio: false,
      disabledDates: [],
      isError: false,
    };
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && this.props.show !== prevProps.show) {
      this.setState({
        data: this.props.dataExpertiseForm,
        history: this.props.dataExpertiseFormPage.length
          ? this.props.dataExpertiseFormPage
          : [0],
        currentPage: this.props.dataExpertiseFormPage.length
          ? this.props.dataExpertiseFormPage[
              this.props.dataExpertiseFormPage.length - 1
            ]
          : 0,
      });
    }

    if (
      this.state.currentPage === 9 &&
      prevState.currentPage !== this.state.currentPage
    ) {
      this.getAvailabilityAgency();
    }

    if (
      this.state.data.expertiseLocation_radio !==
      prevState.data.expertiseLocation_radio
    ) {
      this.getInputExpertiseLocation();
    }
  }

  getInputExpertiseLocation = () => {
    let dataInput = "";

    if (
      this.state.data.expertiseLocation_radio ===
      "À notre agence la plus proche ?"
    ) {
      dataInput = this.context.agency.name
        ? `${this.context.agency.name}, ${this.context.agency.address1}, ${this.context.agency.zipCode} ${this.context.agency.city}`
        : "";
    } else if (
      this.state.data.expertiseLocation_radio === "À votre domicile ?"
    ) {
      dataInput = `${this.context.client.address1}, ${this.context.client.zipCode} ${this.context.client.city}`;
    } else if (
      this.state.data.expertiseLocation_radio === "Sur votre lieu de travail ?"
    ) {
      dataInput = "";
    } else if (
      this.state.data.expertiseLocation_radio === "Chez votre réparateur ?"
    ) {
      dataInput = this.context.garage.name
        ? `${this.context.garage.name}, ${this.context.garage.address1} ${this.context.garage.address2}, ${this.context.garage.zipCode} ${this.context.garage.city}`
        : "";
    }

    let tmpData = this.state.data;

    tmpData.inputExpertiseLocation = dataInput;

    this.setState({ data: tmpData });
  };

  onClose = (callback = null, isEnd = false) => {
    const data = this.state.data;
    const history = this.state.history;

    this.setState(
      {
        currentPage: 0,
        history: [],
        data: {},
        isCalendarVisio: false,
      },
      () => {
        this.props.onClose(data, history, callback, isEnd);
      }
    );
  };

  updateData = (name, value, callback = () => {}) => {
    let data = JSON.parse(JSON.stringify(this.state.data));
    data[name] = value;

    this.setState(
      {
        data,
      },
      callback
    );
  };

  checkRadio = (value) => {
    if (value === 0 || value) {
      return true;
    } else {
      return false;
    }
  };

  getCorrectInput = (isExist, name, text, required = true) => {
    const data = this.state.data;

    if (isExist) {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormRadio
            text="Oui"
            value={1}
            className="mb-10"
            checked={data[`${name}_radio`] === 1}
            onChange={() => this.updateData(`${name}_radio`, 1)}
          />
          <FormRadio
            text="Non"
            value={0}
            className="mb-10"
            checked={data[`${name}_radio`] === 0}
            onChange={() => this.updateData(`${name}_radio`, 0)}
          />
          {data[`${name}_radio`] === 0 ? (
            <FormInput
              title="Correction"
              value={data[`${name}_text`]}
              onChange={({ target }) =>
                this.updateData(`${name}_text`, target.value)
              }
            />
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormInput
            value={data[`${name}_text`] ? data[`${name}_text`] : ""}
            onChange={({ target }) => {
              this.updateData(`${name}_radio`, 0);
              this.updateData(`${name}_text`, target.value);
            }}
          />
        </>
      );
    }
  };

  getRadio = (name, text, required = true, values = []) => {
    const data = this.state.data;

    return (
      <>
        <p>
          {text}
          {required ? <span className="red"> *</span> : null}
        </p>
        {values.length ? (
          <>
            {values.map((value, idx) => (
              <FormRadio
                key={idx}
                text={value}
                value={value}
                className="mb-10"
                checked={data[`${name}_radio`] === value}
                onChange={() => this.updateData(`${name}_radio`, value)}
              />
            ))}
          </>
        ) : (
          <>
            <FormRadio
              text="Oui"
              value={1}
              className="mb-10"
              checked={data[`${name}_radio`] === 1}
              onChange={() => this.updateData(`${name}_radio`, 1)}
            />
            <FormRadio
              text="Non"
              value={0}
              className="mb-10"
              checked={data[`${name}_radio`] === 0}
              onChange={() => this.updateData(`${name}_radio`, 0)}
            />
          </>
        )}
      </>
    );
  };

  getSelfcareWindtransfer() {
    if (
      !this.state.data.urlWindTransfer ||
      this.state.data.statusWindTransfer
    ) {
      getSelfcareWindtransferExpertiseSolution(
        {
          clientName: this.context.client.name,
          reference: this.context.sinister.reference,
          agencyMail: this.context.agency.email,
          licensePlate: this.context.vehicle.licensePlate,
          companyId: this.context.principal.companyId,
        },
        (result) => {
          if (result.success) {
            let data = this.state.data;

            data.urlWindTransfer = result.url;
            data.idWindTransfer = result.id;
            data.statusWindTransfer = false;

            this.setState({ data: data, isModalWindTransfer: true }, () => {
              this.props.updateExpertiseSolution(
                this.state.data,
                this.state.history
              );
            });
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    } else {
      this.setState({ isModalWindTransfer: true });
    }
  }

  getVisioconferenceXpertivisio(callback) {
    getVisioconferenceXpertvisioExpertiseSolution(
      {
        folderId: this.context.id,
        dateAppointment: this.state.data.dateCalendarVisio,
        hourAppointment: this.state.data.timeCalendarVisio,
      },
      (result) => {
        if (result.success) {
          let tmpData = this.state.data;

          tmpData.idVisio = result.id;
          tmpData.tokenVisio = result.token;

          this.setState({ data: tmpData }, () => {
            this.props.updateExpertiseSolution(
              this.state.data,
              this.state.history
            );
          });
        } else {
          alert("Une erreur est survenue");
        }
        callback(result);
      }
    );
  }

  getAvailabilityAgency() {
    getAvailabilityAgencyExpertiseSolution(
      {
        date: moment().format("YYYY-MM-DD"),
        agency: this.context.agency.id,
      },
      (result) => {
        if (result.success) {
          this.setState({ disabledDates: result.disabledDates });
        } else {
          this.setState({ disabledDates: [] });
        }
      }
    );
  }

  backPage = () => {
    let tmpHistory = this.state.history.slice(0, -1);

    this.setState({
      currentPage: this.state.history[this.state.history.length - 2],
      history: tmpHistory,
    });
  };

  nextPage = (page = null) => {
    let tmpHistory = this.state.history;
    let tmpPage = page !== null ? page : this.state.currentPage + 1;

    tmpHistory.push(tmpPage);

    this.setState({
      currentPage: tmpPage,
      history: tmpHistory,
    });
  };

  getButtonBack = () => {
    if (this.state.history.length - 1 > 0) {
      return (
        <Button
          text="Retour"
          className="m-auto mr-5 w-auto"
          onClick={() => this.backPage()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      );
    }
  };

  getButtonNext = (onClick = () => this.nextPage()) => {
    return (
      <Button text="Continuer" className="m-auto ml-5 w-auto" onClick={onClick}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  };

  getPage = () => {
    switch (this.state.currentPage) {
      case 0:
        return (
          <>
            <h3 className="text-center">
              DÉFINISSONS ENSEMBLE VOTRE SOLUTION D'EXPERTISE
            </h3>
            <p>
              En répondant à ce questionnaire intéractif, vous nous aidez à
              adapter votre parcours afin qu'il corresponde parfaitement à vos
              attentes.
            </p>
            <p>
              En cas de doute ou question, notre équipe dédiée{" "}
              <b>{this.context.principal.companyName}</b> est disponible au{" "}
              <b>{this.context.agency.phone}</b> ou via le formulaire de
              contact.
            </p>
            <div className="d-flex mt-10">
              <Button
                text="Nous contacter"
                className="m-auto mr-5 w-auto"
                outline
                onClick={() =>
                  this.context.handleOpenModalMessage(
                    "contact",
                    "agency",
                    (result) => {
                      this.onClose(() => {});
                    }
                  )
                }
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </Button>
              {this.getButtonNext()}
            </div>
          </>
        );
      case 1:
        return (
          <>
            {this.getCorrectInput(
              !!this.context.client.cellphone,
              "cellphone",
              !!this.context.client.cellphone ? (
                <>
                  Votre numéro de téléphone portable est bien le{" "}
                  <b>{this.context.client.cellphone}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre numéro de téléphone portable</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.client.email,
              "email",
              !!this.context.client.email ? (
                <>
                  Votre adresse e-mail est bien{" "}
                  <b>{this.context.client.email}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre adresse e-mail</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.licensePlate,
              "licensePlate",
              !!this.context.vehicle.licensePlate ? (
                <>
                  L'immatriculation de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.licensePlate}</b> ?
                </>
              ) : (
                <>
                  Merci de renseigner votre l'immatriculation de votre véhicule
                </>
              )
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  (this.state.data.cellphone_radio ||
                    this.state.data.cellphone_text) &&
                  (this.state.data.email_radio || this.state.data.email_text) &&
                  (this.state.data.licensePlate_radio ||
                    this.state.data.licensePlate_text)
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 2:
        // specific case is nature event "hail"
        return (
          <>
            {this.getCorrectInput(
              !!this.context.sinister.position ||
                this.context.sinister.natureEventType === 14,
              "position",
              !!this.context.sinister.position ||
                this.context.sinister.natureEventType === 14 ? (
                <>
                  {this.context.sinister.natureEventType !== 14 ? (
                    <>
                      Les dommages se situent bien sur{" "}
                      <b>{this.context.sinister.position}</b> ?
                    </>
                  ) : (
                    <>
                      Les dommages correspondent bien à des impacts de grêle ?
                    </>
                  )}
                </>
              ) : (
                <>Merci de renseigner la zone des dommages</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.sinister.natureEvent,
              "natureEvent",
              !!this.context.sinister.natureEvent ? (
                <>
                  La nature du sinistre que vous avez subi est bien{" "}
                  <b>{this.context.sinister.natureEvent}</b> ?
                </>
              ) : (
                <>
                  Merci de renseigner la nature du sinistre que vous avez subi
                </>
              )
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  (this.state.data.position_radio ||
                    this.state.data.position_text) &&
                  (this.state.data.natureEvent_radio ||
                    this.state.data.natureEvent_text)
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 3:
        return (
          <>
            {this.getRadio("rolling", "Votre véhicule est-il roulant ?")}
            {this.getRadio(
              "straight",
              "Quand vous roulez en ligne droite, votre volant est-il de travers ?"
            )}
            {this.getRadio("majorDamage", "Les dommages sont-ils importants ?")}
            {this.getRadio(
              "highCosts",
              "Pensez-vous que le montant des réparations risque de dépasser la valeur de votre véhicule ?"
            )}
            {this.getRadio(
              "mechanical",
              "Pensez-vous que des pièces mécaniques ont été touchées ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.checkRadio(this.state.data.rolling_radio) &&
                  this.checkRadio(this.state.data.straight_radio) &&
                  this.checkRadio(this.state.data.majorDamage_radio) &&
                  this.checkRadio(this.state.data.highCosts_radio) &&
                  this.checkRadio(this.state.data.mechanical_radio)
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 4:
        return (
          <>
            {this.getRadio(
              "repair",
              "Souhaitez-vous faire réparer votre véhicule ?",
              true,
              ["Oui", "Non", "Pas nécessairement"]
            )}
            {this.getRadio(
              "blockedGarage",
              "Est-ce que votre véhicule est immobilisé au garage ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.checkRadio(this.state.data.repair_radio) &&
                  this.checkRadio(this.state.data.blockedGarage_radio)
                ) {
                  let page = null;

                  if (this.state.data.blockedGarage_radio === 1) {
                    page = 13;
                  } else if (
                    this.state.data.rolling_radio === 0 ||
                    this.state.data.straight_radio === 1 ||
                    this.state.data.majorDamage_radio === 1 ||
                    this.state.data.highCosts_radio === 1 ||
                    this.state.data.mechanical_radio === 1
                  ) {
                    page = 9;
                  }

                  if (this.context.principal.companyId === 24) {
                    // BPCE DISABLE EAD
                    page = 8;
                  }

                  this.nextPage(page);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 5:
        return (
          <>
            {this.getRadio(
              "smartphone",
              "Possédez-vous un smartphone connecté à internet ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.checkRadio(this.state.data.smartphone_radio)) {
                  let page = null;

                  if (this.state.data.smartphone_radio === 0) {
                    // MACIF DISABLE EAD GARAGE
                    if (this.context.principal.companyId !== 3) {
                      page = 8;
                    } else {
                      page = 9;
                    }
                  }

                  this.nextPage(page);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 6:
        return (
          <>
            {this.getRadio(
              "picture",
              "Souhaitez-vous faire vous-même les photos des dommages de votre véhicule ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.checkRadio(this.state.data.picture_radio)) {
                  let page = 8;

                  if (this.state.data.picture_radio === 1) {
                    page = 10;
                  } else if (this.context.principal.companyId === 3) {
                    page = 9;
                  }

                  this.nextPage(page);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 7:
        return (
          <>
            {this.getRadio(
              "videoConference",
              "Souhaitez-vous faire que notre expert vous assiste en visioexpertise ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.checkRadio(this.state.data.videoConference_radio)) {
                  let page = null;

                  if (this.state.data.videoConference_radio === 1) {
                    page = 11;
                  } else if (this.context.principal.companyId === 3) {
                    // MACIF DISABLE EAD GARAGE
                    page = 9;
                  }

                  this.nextPage(page);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 8:
        return (
          <>
            {this.getRadio(
              "pictureRepairman",
              "Souhaitez-vous que votre garage fasse les photos des dommages de votre véhicule ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.checkRadio(this.state.data.pictureRepairman_radio)) {
                  let page = null;

                  if (this.state.data.pictureRepairman_radio === 1) {
                    page = 12;
                  }

                  this.nextPage(page);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 9:
        return (
          <>
            {this.getRadio(
              "expertiseLocation",
              "Souhaitez-vous que l'expertise soit réalisée",
              true,
              // [
              //   "À notre agence la plus proche ?",
              //   "Chez votre réparateur ?",
              //   "À votre domicile ?",
              //   "Sur votre lieu de travail ?",
              //   "Autre lieu ?",
              // ]
              // ["À notre agence la plus proche ?", "Chez votre réparateur ?"]
              ["À notre agence la plus proche ?"]
            )}
            {this.state.data.expertiseLocation_radio ? (
              <>
                {["Chez votre réparateur ?"].includes(
                  this.state.data.expertiseLocation_radio
                ) &&
                this.context.garage.changeoverDay.filter((date) => date === 0)
                  .length ? (
                  <Button
                    text={
                      this.state.data.dateCalendarAppointment
                        ? `Expertise le ${moment(
                            this.state.data.dateCalendarAppointment
                          ).format("DD/MM/YYYY")}`
                        : "Choisir une date"
                    }
                    onClick={() =>
                      this.setState({ isCalendarAppointment: true })
                    }
                  />
                ) : ["À notre agence la plus proche ?"].includes(
                    this.state.data.expertiseLocation_radio
                  ) ? (
                  this.context.agency.times.type !== 1 &&
                  (this.context.agency.times.startAM ||
                    this.context.agency.times.endAM ||
                    this.context.agency.times.startPM ||
                    this.context.agency.times.endPM) ? (
                    <Button
                      className={this.state.isError ? "bg-red" : ""}
                      text={
                        this.state.data.dateCalendarAppointment &&
                        this.state.data.timeCalendarAppointment
                          ? `Expertise le ${moment(
                              this.state.data.dateCalendarAppointment
                            ).format("DD/MM/YYYY")} à ${
                              this.state.data.timeCalendarAppointment
                            }`
                          : "Choisir une date"
                      }
                      onClick={() =>
                        this.setState({ isCalendarAppointment: true })
                      }
                    />
                  ) : null
                ) : (
                  <>
                    <p>
                      Veuillez contacter l'agence {this.context.agency.name}{" "}
                      pour prendre rendez-vous au{" "}
                      <a
                        style={{ display: "inline-block" }}
                        href={`tel:${this.context.agency.phone}`}
                      >
                        {this.context.agency.phone}
                      </a>
                    </p>
                  </>
                )}
              </>
            ) : null}

            {this.state.data.expertiseLocation_radio ? (
              <>
                <FormInput
                  title="Adresse du lieu de rendez-vous"
                  className="mt-10"
                  placeholder="Adresse, Code postal Ville"
                  value={
                    this.state.data.inputExpertiseLocation
                      ? this.state.data.inputExpertiseLocation
                      : ""
                  }
                  onChange={({ target }) => {
                    let tmpData = this.state.data;
                    tmpData.inputExpertiseLocation = target.value;
                    this.setState({ data: tmpData });
                  }}
                />
              </>
            ) : null}

            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.checkRadio(this.state.data.expertiseLocation_radio) &&
                  this.state.data.inputExpertiseLocation
                ) {
                  if (
                    ["Chez votre réparateur ?"].includes(
                      this.state.data.expertiseLocation_radio
                    ) ||
                    [
                      "Sur votre lieu de travail ?",
                      "À votre domicile ?",
                      "Autre lieu ?",
                    ].includes(this.state.data.expertiseLocation_radio)
                  ) {
                    this.nextPage(13);
                  } else {
                    if (
                      ["À notre agence la plus proche ?"].includes(
                        this.state.data.expertiseLocation_radio
                      )
                    ) {
                      if (
                        this.state.data.dateCalendarAppointment ||
                        (!this.context.agency.times.startAM &&
                          !this.context.agency.times.endAM &&
                          !this.context.agency.times.startPM &&
                          !this.context.agency.times.endPM) ||
                        this.context.agency.times.type == 1
                      ) {
                        if (
                          this.state.data.timeCalendarAppointment ||
                          (!this.context.agency.times.startAM &&
                            !this.context.agency.times.endAM &&
                            !this.context.agency.times.startPM &&
                            !this.context.agency.times.endPM) ||
                          this.context.agency.times.type == 1
                        ) {
                          this.nextPage(13);
                        } else {
                          this.setState(
                            {
                              isError: true,
                            },
                            () => {
                              alert("Veuillez sélectionner une heure");
                            }
                          );
                        }
                      } else {
                        this.setState(
                          {
                            isError: true,
                          },
                          () => {
                            alert("Veuillez sélectionner une date");
                          }
                        );
                      }
                    } else {
                      this.setState(
                        {
                          isError: true,
                        },
                        () => {
                          alert("Veuillez sélectionner une date");
                        }
                      );
                    }
                  }
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 10:
        return (
          <>
            <h4>Prenez vos photos vous-mêmes</h4>
            <p>
              Cliquez sur le bouton "Continuer" pour démarrer la prise de photo
            </p>
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                this.getSelfcareWindtransfer();
              })}
            </div>
          </>
        );
      case 11:
        return (
          <>
            <h4>Prenez RDV avec notre expert VISIO</h4>
            <Button
              className={this.state.isError ? "bg-red" : ""}
              text={
                this.state.data.dateCalendarVisio &&
                this.state.data.timeCalendarVisio
                  ? `Visioconférence le ${moment(
                      this.state.data.dateCalendarVisio
                    ).format("DD/MM/YYYY")} à ${
                      this.state.data.timeCalendarVisio
                    }`
                  : "Choisir une date et une heure"
              }
              onClick={() => this.setState({ isCalendarVisio: true })}
            />
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.state.data.dateCalendarVisio &&
                  this.state.data.timeCalendarVisio
                ) {
                  this.getVisioconferenceXpertivisio((result) => {
                    if (result.success) {
                      this.nextPage(13);
                    }
                  });
                } else {
                  this.setState(
                    {
                      isError: true,
                    },
                    () => {
                      if (!this.state.data.dateCalendarVisio) {
                        alert("Veuillez sélectionner une date");
                      } else if (!this.state.data.timeCalendarVisio) {
                        alert("Veuillez sélectionner une heure");
                      }
                    }
                  );
                }
              })}
            </div>
          </>
        );
      case 12:
        return (
          <>
            {this.context.garage.id ? (
              <>
                <p>
                  Nous vous invitons à prendre rendez-vous avec votre réparateur
                  afin qu'il nous fasse parvenir les photos de votre véhicule,
                  vous pouvez le contacter directement par téléphone au{" "}
                  <a
                    style={{ display: "inline-block" }}
                    href={`tel:${this.context.garage.phone}`}
                  >
                    {this.context.garage.phone}
                  </a>
                </p>

                <p>{this.context.garage.name}</p>
                <p>
                  {this.context.garage.address1} {this.context.garage.address2},{" "}
                  {this.context.garage.zipCode} {this.context.garage.city}
                </p>
              </>
            ) : (
              <>
                <p>
                  Merci de nous indiquer les coordonnées de votre réparateur :
                </p>
                <FormInput
                  className="mt-10"
                  placeholder="Adresse"
                  value={
                    this.state.data.inputGarageAddress
                      ? this.state.data.inputGarageAddress
                      : ""
                  }
                  onChange={({ target }) => {
                    let tmpData = this.state.data;
                    tmpData.inputGarageAddress = target.value;
                    this.setState({ data: tmpData });
                  }}
                />
                <FormInput
                  className="mt-10"
                  placeholder="Code postal"
                  value={
                    this.state.data.inputGarageZipCode
                      ? this.state.data.inputGarageZipCode
                      : ""
                  }
                  onChange={({ target }) => {
                    let tmpData = this.state.data;
                    tmpData.inputGarageZipCode = target.value;
                    this.setState({ data: tmpData });
                  }}
                />
                <FormInput
                  className="mt-10"
                  placeholder="Ville"
                  value={
                    this.state.data.inputGarageCity
                      ? this.state.data.inputGarageCity
                      : ""
                  }
                  onChange={({ target }) => {
                    let tmpData = this.state.data;
                    tmpData.inputGarageCity = target.value;
                    this.setState({ data: tmpData });
                  }}
                />
                <FormInput
                  className="mt-10"
                  placeholder="Téléphone"
                  value={
                    this.state.data.inputGaragePhone
                      ? this.state.data.inputGaragePhone
                      : ""
                  }
                  onChange={({ target }) => {
                    let tmpData = this.state.data;
                    tmpData.inputGaragePhone = target.value;
                    this.setState({ data: tmpData });
                  }}
                />
                <p>
                  Nous vous invitons à prendre rendez-vous avec votre réparateur
                  afin qu'il nous fasse parvenir les photos de votre véhicule.
                </p>
              </>
            )}

            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  this.context.garage.id ||
                  (this.state.data.inputGarageAddress &&
                    this.state.data.inputGarageZipCode &&
                    this.state.data.inputGarageCity &&
                    this.state.data.inputGaragePhone)
                ) {
                  this.nextPage(13);
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 13:
        return (
          <>
            <h4 className="text-center">
              Bravo, la solution d'expertise choisie est :{" "}
              {
                this.props.getTypeExpertise(this.state.data, this.state.history)
                  .type
              }
            </h4>
            {this.state.data.blockedGarage_radio === 1 ? (
              <p>
                Nous avons bien pris en compte vos réponses, l'expert passera
                voir votre véhicule chez votre réparateur au plus vite.
              </p>
            ) : null}
            {this.getRadio(
              "PRE",
              "Soucieux(se) du respect de l'environnement, j'autorise le montage de pièces issues de l'économie circulaire pour la réparation de son véhicule"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              <Button
                text="Valider"
                className="m-auto ml-5 w-auto"
                onClick={() => this.onClose(null, true)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </>
        );

      default:
        return <div className="d-flex mt-10">{this.getButtonBack()}</div>;
    }
  };

  render() {
    let timesRange = [];

    if (this.context.agency.times.startAM && this.context.agency.times.endAM) {
      timesRange.push([
        this.context.agency.times.startAM,
        this.context.agency.times.endAM,
      ]);
    }
    if (this.context.agency.times.startPM && this.context.agency.times.endPM) {
      timesRange.push([
        this.context.agency.times.startPM,
        this.context.agency.times.endPM,
      ]);
    }

    return (
      <>
        <Modal
          className="m-auto"
          show={this.props.show}
          onClose={() => this.onClose()}
          large
        >
          {this.getPage()}
        </Modal>
        <IframeWindTransfer
          show={this.state.isModalWindTransfer}
          url={this.state.data.urlWindTransfer}
          onClose={(status) => {
            let data = this.state.data;

            if (status) {
              data.statusWindTransfer = true;
            }

            this.setState(
              {
                isModalWindTransfer: false,
                data,
              },
              () => {
                if (status) {
                  this.nextPage(13);
                }

                this.props.updateExpertiseSolution(
                  this.state.data,
                  this.state.history
                );
              }
            );
          }}
        />
        <Calendar
          show={this.state.isCalendarVisio}
          valueDate={this.state.data.dateCalendarVisio}
          valueTime={this.state.data.timeCalendarVisio}
          onClose={() => this.setState({ isCalendarVisio: false })}
          onValid={(valueDate, valueTime) => {
            let tmpData = this.state.data;

            tmpData.dateCalendarVisio = valueDate;
            tmpData.timeCalendarVisio = valueTime;

            this.setState({
              isCalendarVisio: false,
              data: tmpData,
              isError: false,
            });
          }}
          disabledDates={this.state.disabledDates}
          isWeek={false}
          isHoliday={false}
          minDate={moment().format("YYYY-MM-DD")}
          isTime={true}
          timeStep={15}
          timesRange={timesRange}
          disabledTime={[]}
        />
        <Calendar
          show={this.state.isCalendarAppointment}
          valueDate={this.state.data.dateCalendarAppointment}
          valueTime={this.state.data.timeCalendarAppointment}
          onClose={() => this.setState({ isCalendarAppointment: false })}
          onValid={(valueDate, valueTime) => {
            let tmpData = this.state.data;

            tmpData.dateCalendarAppointment = valueDate;
            tmpData.timeCalendarAppointment = valueTime;

            this.setState({
              isCalendarAppointment: false,
              data: tmpData,
              isError: false,
            });
          }}
          disabledDates={this.state.disabledDates}
          disabledDays={
            ["Chez votre réparateur ?"].includes(
              this.state.data.expertiseLocation_radio
            )
              ? this.context.garage.changeoverDay
              : []
          }
          isWeek={false}
          isHoliday={false}
          minDate={moment().add(2, "days").format("YYYY-MM-DD")}
          isTime={
            ["Chez votre réparateur ?"].includes(
              this.state.data.expertiseLocation_radio
            )
              ? false
              : true
          }
          timeStep={15}
          timesRange={timesRange}
          disabledTime={[]}
        />
      </>
    );
  }
}
