import React from "react";
import { ButtonIcon, Card, FullModal } from "ui-kit-ck-consultant";
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFileUrl } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalPicture: false,
      modalIdx: null,
      pictures: [],
    };
  }

  static contextType = AuthContext;

  render() {
    return (
      <>
        <Card
          title="Photos du dossier"
          className="h-100"
          classNameChildren="mt-auto mb-auto d-flex overflow-x-auto"
        >
          <div
            style={{ whiteSpace: "nowrap" }}
            className="d-flex ml-auto mr-auto"
          >
            {this.props.dataPictures.map((picture, idx) => (
              <div
                key={picture.token}
                className={"d-flex flex-column"}
                style={{ position: "relative" }}
              >
                {this.context.source === "agency" && (
                  <div
                    className="p-5 cursor-pointer"
                    style={{
                      position: "absolute",
                      top: -10,
                      left: -5,
                      background: "#E54E4E",
                      borderRadius: "100%",
                      zIndex: 10,
                    }}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir masquer cette image ?"
                        )
                      ) {
                        this.context.hideFile(
                          this.props.dataPictures[idx].token
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faEyeSlash} color="white" />
                  </div>
                )}
                <img
                  className="cursor-pointer mb-10 mr-10"
                  style={{
                    height: "120px",
                    border: "solid 1px gray",
                    borderRadius: "8px",
                    minWidth: 80,
                  }}
                  alt={`folder-${idx}`}
                  loading="lazy"
                  src={getFileUrl(
                    picture.folderId,
                    picture.token,
                    picture.source
                  )}
                  onClick={() =>
                    this.setState({
                      isModalPicture: true,
                      modalIdx: idx,
                    })
                  }
                />
              </div>
            ))}
          </div>
        </Card>
        <FullModal
          show={this.state.isModalPicture}
          onClose={() =>
            this.setState({
              isModalPicture: false,
              modalIdx: null,
            })
          }
          classNameChildren="d-flex"
        >
          {this.props.dataPictures[this.state.modalIdx] ? (
            <div className="m-auto">
              <img
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                alt="full"
                src={getFileUrl(
                  this.props.dataPictures[this.state.modalIdx].folderId,
                  this.props.dataPictures[this.state.modalIdx].token,
                  this.props.dataPictures[this.state.modalIdx].source
                )}
              />
              <div
                className="d-flex"
                style={{
                  gap: 10,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonIcon
                  isBackground
                  small
                  disabled={this.state.modalIdx === 0}
                  onClick={() =>
                    this.setState({ modalIdx: this.state.modalIdx - 1 })
                  }
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </ButtonIcon>
                <span>
                  {this.state.modalIdx}/{this.props.dataPictures.length}
                </span>
                <ButtonIcon
                  isBackground
                  small
                  disabled={
                    this.state.modalIdx + 1 >= this.props.dataPictures.length
                  }
                  onClick={() =>
                    this.setState({ modalIdx: this.state.modalIdx + 1 })
                  }
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </ButtonIcon>
                <ButtonIcon
                  isBackground
                  small
                  onClick={() => {
                    const url = getFileUrl(
                      this.props.dataPictures[this.state.modalIdx].folderId,
                      this.props.dataPictures[this.state.modalIdx].token,
                      this.props.dataPictures[this.state.modalIdx].source
                    );

                    fetch(url)
                      .then((response) => response.blob())
                      .then((blob) => {
                        const urlBlob = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = urlBlob;
                        link.download =
                          `${
                            this.props.dataPictures[this.state.modalIdx]
                              .filename
                          }.${
                            this.props.dataPictures[this.state.modalIdx]
                              .extension
                          }` || "image.jpg";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(urlBlob);
                      })
                      .catch(console.error);
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </ButtonIcon>
              </div>
            </div>
          ) : null}
        </FullModal>
      </>
    );
  }
}
